.jumptarget::before {
  content:"";
  display:block;
  height:14vw; /* fixed header height*/
  margin:-14vw 0 0; /* negative fixed header height */
}

.about {
    margin-left: 8vw;
    margin-right: 8vw;
    color: #3C3C3C;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.about h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5vw;
    letter-spacing: 0.15vw;
}

.line {
    margin-top: 0;
    margin-bottom: 2vh;
    border-top: 0.25vw solid #1D4972;
    width: 15vw;
    text-align: left;
}

.about_body {
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.05vw;
    font-size: 17px;
    text-align: justify;
    text-justify: inter-word;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.disclaimer {
    width: 100%;
    text-align: center;
    padding: 1rem;
    font-size: 13px;
}

@media(max-width: 768px) {
    .about h1 {
        font-size: 3vw;
    }

    .line {
        margin-bottom: 2vh;
        border-top: 0.75vw solid #1D4972;
        width: 30vw;
        text-align: left;
    }
    
    .about_body {
        width: 100%;
    }
    .disclaimer {
        text-align: left;
    }
}