.contact {
    padding-top: 2vh;
    position: absolute;
    width: 100%;
    height: auto;
    background-color: #0F2134;
    color: white;
    margin-bottom: 0;
    text-align: center;
}

.contact h1 {
    text-align: left;
    margin-left: 8vw;
    font-size: 1.5vw;
    letter-spacing: 0.15vw;
}

.title {
    font-family: 'Libre Baskerville', serif;
}

.line {
    margin-top: 0;
    margin-bottom: 2vh;
    margin-left: 8vw;
    border-top: 0.25vw solid #204973;
    width: 17vw;
    text-align: center;
}

.image {
    width: 25%;
}

.content {
    height: 80px;
}

.contact_info {
    text-align: center;
    width: 40%;
    font-size: 1.33vw;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
    color: white;
    width: 100%;
}

.contact_info a {
    color: white;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.contact_info {
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.address {
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.map {
    width: 40%;
    height: 100%;
    text-align: right;
}

.copy {
    margin-top: 2vw;
}

@media(max-width: 768px) {
    .contact h1 {
        font-size: 3vw;
    }

    .image {
       width: 50%;
    }

    .content {
        flex-direction: column;
        height: 80px;
        justify-content: flex-start;
    }

    .contact_info {
        width: 100%;
        font-size: 4vw;
        text-align: center;
    }

    .map {
        height: 50%;
        width: 100%;
    }

    .copy {
        font-size: 2.75vw;
    }
}