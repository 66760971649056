.clients {
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
}

.clients h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5vw;
    letter-spacing: 0.15vw;
    color: #3C3C3C;
}

.line {
    margin-top: 0;
    margin-bottom: 2vh;
    border-top: 0.25vw solid #1D4972;
    width: 15vw;
    text-align: left;
}

.subtitle {
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5vw;
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
     color: #3C3C3C;
}

li {
    list-style-type: none;
    font-size: 1.2vw;
    padding: 0.25vw;
    font-weight: 400;
}

.disclaimer {
    width: 100%;
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 0rem;
    padding-left: 22.5%;
    font-size: 13px;
}

@media(max-width: 768px) {
    .clients h1 {
        font-size: 3vw;
    }

    .line {
        width: 30vw;
        border-top: 0.75vw solid #0F2134;
    }

    .subtitle {
        font-size: 3.5vw;
        margin-top: 4.5vw;
        margin-bottom: 4.5vw;
    }

    li {
        font-size: 3vw;
    }

    .column {
        margin-bottom: 0;
    }
}