.jumptarget::before {
  content:"";
  display:block;
  height:6vw; /* fixed header height*/
  margin:-6vw 0 0; /* negative fixed header height */
}

.banner {
    background: #204972;
    font-family: 'Libre Baskerville', serif;
    width:100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 6vw;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.portrait_row {
    padding-right: 0px;
}
.portrait {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 50rem;
    margin: none;
}

.text {
    position: relative;
    text-align: left;
    color: #C9DAF1;
    font-weight: 600;
    display: block;
    height: 100%;
    padding-left: 0px;
}

.joshua {
    padding-top: 7vh;
    padding-left: 2vw; 
    padding-bottom: 3vh;
    font-size: 2.5vw;
    letter-spacing: 0.1vw;
}

.appellate {
    padding-top: 3vh;
    padding-left: 2vw; 
    padding-bottom: 7vh;
    font-size: 2vw;
    letter-spacing: 0.3vw;
}

.tagline {
    color: white;
    background: #0000008c;
    width:100%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: -2vw; 
    display: flex;
    align-content: center;
}

.tagline h1 {
    font-weight: 600;
    font-size: 3.5vw;
    padding-left: 2vw; 
}

@media(max-width: 1000px) {
    .banner {
        margin-top: 13vw;
    }
    .portrait {
        display: none;
    }

    .text {
        width: 100% !important;
        text-align: center;
    }

    .tagline {
        display: block;
    }

    .tagline {
        font-size: 6vw;
    }

    .joshua {
        padding-top: 2.5vh;
        padding-bottom: 1.5vh;
        font-size: 4vw;
        letter-spacing: 0.2vw;
    }

    .appellate {
        padding-top: 1.5vh;
        padding-bottom: 2.5vh;
        font-size: 3.5vw;
        letter-spacing: 0.5vw;
    }
}

@media(max-width: 1024px) {
    .banner {
        margin-top: 6vw;
    }
}

@media(max-width: 768px) {
    .banner {
        margin-top: 12vw;
    }
}