.jumptarget::before {
  content:"";
  display:block;
  height:10vw; /* fixed header height*/
  margin:-10vw 0 0; /* negative fixed header height */
}

.background {
    background-color: #0F2134;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.appeals {
    margin-left: 8vw;
    margin-right: 8vw;
    color: white;
}

.appeals h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5vw;
    letter-spacing: 0.15vw;
}

.line {
    margin-top: 0;
    margin-bottom: 2vh;
    border-top: 0.25vw solid #204973;
    width: 15vw;
    text-align: left;
}

.examples {
    text-align: center;
}

.example_body {
    margin: 1vw;
    width: 30%;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    transition: all .2s ease-in-out;
}

.example_text {
    text-align: justify;
    text-justify: inter-word; 
    font-size: 16px;
}

.more {
    width: 100%;
    text-align: center;
    margin-bottom: 5vh;
}

.more_button {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.1vw;
    text-align: center;
    margin-top: 5vh;
    transition: 0.5s;
}

.bottom_line {
    border-top: 2px solid #204973;
}

@media(max-width: 1000px) {
    .example_body {
        width: 100%;
    }
}

@media(max-width: 768px) {
    .appeals h1 {
        font-size: 3vw;
    }

    .line {
        width: 30vw;
        border-top: 0.75vw solid #204973;
    }
    
    .about_body {
        width: 100%;
    }

    .more_button {
        font-size: 2.5vw;
    }
}