.navbar {
    position: fixed !important;
    z-index: 10;
    top: 0;
    width: 100%;
    margin: none !important;
    border: none !important;
    margin-bottom:0px !important;
    font-family: Arial, sans-serif; 
    font-weight: 600;
    font-size: 1.25vw;
    background-color: white !important;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
}

.sticky {
    position: fixed !important;
}

.logo_image {
    height: 6vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.link {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
}

.link a {
    color: #616161 !important;
    transition-duration: 0.25s;
}

.link a:hover {
    color: #9d9d9d !important;
}

@media(max-width: 768px) {
    .logo_image {
        height: 12vw;
        margin-left: 5vw;
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .navbar {
        font-size: 2vw;
    }

    .link {
        font-size: 3.5vw;
    }
}